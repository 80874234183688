import { useEffect } from "react"

export default function useScrollTo(position) {
    useEffect(() => {
        switch (position) {
            case "top":
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'instant',
                });
                break;
            case "bottom":
                window.scroll({
                    bottom: 0,
                    left: 0,
                    behavior: 'instant',
                });
                break;
            default:
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'instant',
                });
                break;
        }
    }, [position])
}