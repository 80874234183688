import { useEffect, useState } from "react"
import { request } from 'graphql-request';

function useFetch(query) {
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        (
            async function () {
                try {
                    setLoading(true)
                    const response = await request('https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/clgp81s3w080j01upbdx24qnh/master', query);
                    setData(response)
                } catch (err) {
                    setError(err)
                } finally {
                    setLoading(false)
                }
            }
        )()
    }, [query])

    return { data, error, loading }

}

function fetchData(query) {
    return request('https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/clgp81s3w080j01upbdx24qnh/master', query);
}

export { useFetch, fetchData }