import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef, useLayoutEffect } from "react";

// import { usePresence } from 'framer-motion'

import Media from './Media';

gsap.registerPlugin(ScrollTrigger);

function Gallery({ topics, innerRef }) {

    const contentRef = useRef();
    const galleryRef = useRef();
    const markerRef = useRef();

    const galleryImages = topics.map((topic, index) => (
        <div className="gallery__item" key={topic?.id ?? ""} style={{ zIndex: 100 - index }}>
            <Media className="gallery__media" src={topic.Topic_media_alias?.url ?? ""} mimeType={topic.Topic_media_alias?.mimeType ?? ""} alt="" />
            <div className="gallery__overlay">
                <div className="content">
                    <h3>{topic?.Topic_title_alias ?? ""}</h3>
                    <p>{topic?.Topic_description_alias ?? ""}</p>
                </div>
            </div>
        </div>
    ))

    useLayoutEffect(() => {
        // pin the content container
        // get the amount of items
        // get the height of the content container
        // change the end position of the content container to height x items
        // modify the start and end positions of every item

        let ctx = gsap.context((self) => {

            const timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: innerRef.current,
                    start: "bottom top",
                    end: () => `bottom+=${galleryRef.current.clientHeight * (galleryImages.length)}px top`,
                    scrub: true,
                    pin: markerRef.current,
                    pinSpacing: true
                },
            });

            const items = self.selector(".gallery__item");

            for (let i = 0; i < items.length; i++) {
                let overlay = items[i].querySelector(".gallery__overlay");
                timeline.to(items[i], {
                    duration: 1,
                    ease: "linear",
                    clipPath: 'inset(0% 0% 100% 0%)',
                    stagger: (index) => index,
                }).from(overlay, {
                    duration: 0.15,
                    backgroundColor: "#000000bb",
                    stagger: (index) => index,
                }, "-=1")
            }

        }, contentRef);

        return () => {
            ctx.revert(); // cleanup
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="gallery__marker" ref={markerRef}></div>
            <section className="gallery" ref={galleryRef}>
                <div ref={contentRef} className="gallery__content">
                    {galleryImages}
                </div>
            </section>
        </>
    )
}

export default Gallery