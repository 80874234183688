import useScrollTo from '../hooks/useScrollTo';

function Impressum() {

    useScrollTo("top");


    return (
        <main id="main" className="page">
            <section className="impressum offset">
                <div className="impressum__content">
                    <h1 className="impressum__headline ff-cy-bold fs-700 line-height-3 m-1">
                        Impressum
                    </h1>
                    <p><strong>Verantwortlicher Dienstanbieter nach § 5 Telemediengesetz</strong></p>
                    <p>Quandel Staudt Design GmbH<br />Schleusenstraße 17<br />60327 Frankfurt am Main<br />Deutschland<br />T&nbsp;+49 (0) 69 24&nbsp;27&nbsp;77-51<br />T&nbsp;+49 (0) 69 24&nbsp;27&nbsp;77-53<br /><a href="mailto:info@quandelstaudt.com">info@quandelstaudt.com</a><br /><a href="https://quandelstaudt.com" target="_blank" rel="noreferrer">quandelstaudt.com</a></p>
                    <p>Amtsgericht Frankfurt am Main<br />Handelsregister HRB103328</p>

                    <p>Geschäftsführer/Gesellschafter:<br />Roger Quandel, Dipl. Designer<br />Marcel Staudt, Dipl. Designer</p>

                    <p>Rechtliche Hinweise</p>

                    <p>Copyright © 2015, QUANDEL STAUDT, alle Rechte vorbehalten.</p>

                    <p>Die Inhalte dieser Website sind urheberrechtlich geschützt. Ohne schriftliche Genehmigung seitens QUANDEL STAUDT darf der Inhalt dieser Seiten in keiner Form reproduziert oder unter Verwendung elektronischer Systeme verarbeitet, vervielfältigt oder verbreitet werden.</p>

                    <p><strong>Haftungsausschluss</strong></p>

                    <p>QUANDEL STAUDT ist nicht verantwortlich für die Inhalte externer Internetseiten. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der gelinkten/verknüpften Seiten hat QUANDEL STAUDT keinerlei Einfluss. Sie distanziert sich daher ausdrücklich von allen Inhalten aller gelinkten/verknüpften Seiten. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcher Art dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde.</p>

                    <p><strong>Urheberrecht &amp;&nbsp;Datenschutzerklärung</strong></p>

                    <p>Technisch bedingt werden bei jedem Besuch unserer Homepage Informationen über IP-Adresse, verweisende URL, Datum/Uhrzeit, Browserversion und verwendetes Betriebssystem in den Server-eigenen Logdateien gespeichert. Diese Daten sind in keiner Weise bezogen auf individuelle Personen und werden nur intern, vertraulich und im Sinne der Datenschutzgesetze zur Seitenoptimierung und zum Schutze vor Missbrauch protokolliert. Die Auswertung erfolgt anonym. Hierzu nutzen wir die kostenlose Software von „Google Analytics“.</p>

                    <p>Dazu stellt Google diese Erklärung zur Verfügung:</p>

                    <p>“Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. (“Google”). Google Analytics verwendet sog. “Cookies”, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglicht. Die durch den Cookie erzeugten Informationen über Ihre Benutzung diese Website (einschließlich Ihrer IP-Adresse) wird an einen Server von Google in den USA übertragen und dort gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten der Google in Verbindung bringen.</p>

                    <p>Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.”</p>

                    <p>Wenn Ihr Besuch nicht in die Auswertung mit einfließen soll, dann bitten wir Sie (wie von Google vorgeschlagen) in ihrem Browser die Cookies zu deaktivieren und ggf. einen Anonymisierungsdienst zu verwenden.</p>
                </div>
            </section>
        </main>
    )
}

export default Impressum