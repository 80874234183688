import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(true);
    const [cookiePreferences, setCookiePreferences] = useState(null);

    useEffect(() => {
        const cookiePreferencesJson = localStorage.getItem('cookiePreferences');
        if (cookiePreferencesJson) {
            // console.log('Cookie preferences found');
            const preferences = JSON.parse(cookiePreferencesJson);
            setCookiePreferences(preferences);
            setShowBanner(false);
        }
    }, []);

    useEffect(() => {
        if (cookiePreferences === null) {
            return;
        }
        if (cookiePreferences.analytics) {
            // Load analytics script
            ReactGA.initialize('G-ZYMBZN22L2');
            // Send pageview with a custom path
            ReactGA.send({ hitType: "pageview", page: "/", title: "neublau.digital" });
            setShowBanner(false);
        }
        if (!cookiePreferences.analytics) {
            setShowBanner(false);
        }
    }, [cookiePreferences]);

    const handlePreferences = (preferences) => {
        setCookiePreferences(preferences);
        localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
    };

    return (
        <>
            {showBanner && (
                <div className="cookie-banner">
                    <div className="cookie-banner__content">
                        <p className="cookie-banner__text">
                            Um unsere Website zu verbessern und Ihnen ein optimales Erlebnis zu bieten, verwenden wir Cookies und setzen dabei auch auf Google Analytics zur Analyse unserer Website-Aktivitäten.{' '}
                            <Link to="/datenschutz">Mehr erfahren</Link>
                        </p>
                        <div className="cookie-banner__preferences">
                            <div className="cookie-banner__preference">
                                <button className="cookie-banner__preference-button" aria-label='accept' onClick={() => handlePreferences({ analytics: true })}>
                                    Akzeptieren
                                </button>
                            </div>
                            <div className="cookie-banner__preference">
                                <button className="cookie-banner__preference-button" aria-label='decline' onClick={() => handlePreferences({ analytics: false })}>
                                    Ablehnen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
};

export default CookieBanner;