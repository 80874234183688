import React, { useState, useMemo, useContext } from 'react';
import { useFrame, useThree } from '@react-three/fiber'
import * as THREE from "three";
import Frame from './Frame';
import VideoFrame from './VideoFrame';

import { ScreenContext } from '../context/ScreenContext.js';

function Scene({ services, hoveredItem }) {
    const { camera, size, viewport } = useThree();
    const [pos, setPos] = useState(new THREE.Vector3(0, 0, 0));
    const { contextData } = useContext(ScreenContext);

    useFrame(() => {
        // Get the mouse position relative to the viewport
        const mouse = {
            x: (contextData.mousePos.x / size.width) * 2 - 1,
            y: -(contextData.mousePos.y / size.height) * 2 + 1,
        };

        const posVec = new THREE.Vector3(mouse.x, mouse.y, 0.5);

        posVec.unproject(camera);
        posVec.sub(camera.position).normalize();

        // Convert the mouse position to three.js world coordinates
        setPos(posVec);
    });

    const frames = useMemo(() => {
        return services.map((service, index) => (
            service.heroMedia.mimeType === 'video/mp4' ?
                <VideoFrame
                    key={index}
                    service={service}
                    pos={pos}
                    hoveredItem={hoveredItem}
                    index={index}
                    viewport={viewport}
                />
                :
                <Frame
                    key={index}
                    service={service}
                    pos={pos}
                    hoveredItem={hoveredItem}
                    index={index}
                    viewport={viewport}
                />
        ));
    }, [services, pos, hoveredItem, viewport]);

    return (
        <>
            {frames}
        </>
    );
}

export default Scene;