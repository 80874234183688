import { useEffect, useState } from 'react';

import './styles/main.scss';

import { ScreenContext } from './context/ScreenContext';
import Layout from './components/Layout';

function App() {
  const [windowWidth, setWindowWidth] = useState(0);

  const [contextData, setContextData] = useState({
    slug: null,
    hoveredItem: null,
    clickedItem: null,
    mousePos: { x: 0, y: 0 },
    clicked: false,
    loading: true,
    services: null,
  });

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ScreenContext.Provider value={{ contextData, setContextData }}>
      <Layout windowWidth={windowWidth} />
    </ScreenContext.Provider>
  );
}

export default App;