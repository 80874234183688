function Media({ className, src, mimeType, width, height, alt, autoPlay = true, controls = false, loop = true, muted = true }) {
    switch (mimeType) {
        case 'video/webm':
            return (
                <video
                    src={src}
                    width={width}
                    height={height}
                    autoPlay={autoPlay}
                    controls={controls}
                    loop={loop}
                    className={`media ${className}`}
                    playsInline
                    muted={muted}
                >
                    <source src={src} type={mimeType} />
                </video>
            )
        case 'video/mp4':
            return (
                <video
                    src={src}
                    width={width}
                    height={height}
                    autoPlay={autoPlay}
                    controls={controls}
                    loop={loop}
                    className={`media ${className}`}
                    playsInline
                    muted={muted}
                >
                    <source src={src} type={mimeType} />
                </video>
            )
        default:
            return (
                <img
                    src={src}
                    width={width}
                    height={height}
                    alt={alt}
                    className={`media ${className}`} />
            )
    }
}

export default Media