import useScrollTo from '../hooks/useScrollTo';

function Datenschutz() {

    useScrollTo("top");

    return (
        <main id="main" className="page" exit={{ opacity: 0 }}>
            <section className="datenschutz offset">
                <div className="datenschutz__content">
                    <h1 className="datenschutz__headline ff-cy-bold fs-700 line-height-3 m-1">
                        Datenschutzerklärung
                    </h1>
                    <p>Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst und behandeln diese vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>

                    <p><strong>Verwendung von Google Analytics</strong></p>

                    <p>Wir nutzen auf unserer Website Google Analytics, einen Webanalysedienst der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; "Google"). Google Analytics verwendet Cookies, die eine Analyse der Benutzung unserer Website ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.</p>

                    <p>Im Falle der Aktivierung der IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Google wird diese Informationen in unserem Auftrag verwenden, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen uns gegenüber zu erbringen.</p>

                    <p>Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.</p>

                    <p>Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen unserer Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noreferrer">http://tools.google.com/dlpage/gaoptout?hl=de</a>.</p>

                    <p>Weitere Informationen zu Nutzungsbedingungen und Datenschutz von Google Analytics finden Sie unter <a href="https://www.google.com/analytics/terms/de.html" target="_blank" rel="noreferrer">https://www.google.com/analytics/terms/de.html</a> bzw. unter <a href="https://www.google.de/intl/de/policies/" target="_blank" rel="noreferrer">https://www.google.de/intl/de/policies/</a>.</p>
                </div>
            </section>
        </main>
    )
}

export default Datenschutz