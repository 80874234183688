const DisplacementMaterial = ({ uniforms }) => {

    const fragmentShader = `
    uniform sampler2D uTexture;
    uniform float uAlpha;
    varying vec2 vUv;
    
    void main() {
        vec3 texture = texture2D(uTexture, vUv).rgb;
        gl_FragColor = vec4(texture, uAlpha);
    }
        `;

    const vertexShader = `
    uniform vec2 uOffset;
    varying vec2 vUv;
    
    void main() {
      vUv = uv;
    
      vec4 modelPosition = modelMatrix * vec4(position, 1.0);
      modelPosition.z -= sin(modelPosition.x) * uOffset.x * 2.0;
      modelPosition.z -= sin(modelPosition.y) * uOffset.y * 2.0;
      vec4 viewPosition = viewMatrix * modelPosition;
      vec4 projectedPosition = projectionMatrix * viewPosition;
    
      gl_Position = projectedPosition;
    }`

    return <shaderMaterial uniforms={uniforms} vertexShader={vertexShader} fragmentShader={fragmentShader} depthWrite={false} transparent={true} />;
}

export default DisplacementMaterial;