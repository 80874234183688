import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useFrame } from '@react-three/fiber'
import * as THREE from "three";
import { useVideoTexture } from '@react-three/drei';
import gsap from 'gsap';
import { lerp } from '../utilities/utils.js';
import DisplacementMaterial from './DisplacementMaterial.js';

function VideoFrame({ service, pos, hoveredItem, index, viewport }) {
    const [isVisible, setIsVisible] = useState(false);
    const [planeWidth, setPlaneWidth] = useState(0);
    const [planeHeight, setPlaneHeight] = useState(0);

    // Change the isVisible state when the hoveredItem changes
    useEffect(() => {
        setIsVisible(hoveredItem === index);
    }, [hoveredItem, index]);

    const video = service.heroMedia.url;

    const videoRef = useRef();

    const videoTexture = useVideoTexture(video, {
        start: true,
        loop: true,
        muted: true,
        playsInline: true,
        crossOrigin: "anonymous"
    });

    const uniforms = useMemo(
        () => ({
            uOffset: { value: new THREE.Vector2(0, 0) },
            uAlpha: { value: 0.0 },
            uTexture: { value: videoTexture }
        }), [videoTexture]
    );

    // Set the plane dimensions based on the image dimensions
    useMemo(() => {
        // const aspectRatio = service.heroMedia.width / service.heroMedia.height;
        const aspectRatio = 1920 / 1080;

        const maxViewportWidth = viewport.width / 2;
        const maxViewportHeight = viewport.height / 1.5;

        if (aspectRatio < 1) {
            setPlaneWidth(maxViewportHeight * aspectRatio);
            setPlaneHeight(maxViewportHeight);
        }
        else if (aspectRatio > 1) {
            setPlaneWidth(maxViewportWidth);
            setPlaneHeight(maxViewportWidth / aspectRatio);
        }
        else {
            setPlaneWidth(maxViewportWidth);
            setPlaneHeight(maxViewportWidth);
        }
    }, [viewport]);

    // Set the plane position based on the mouse position
    useFrame(() => {
        const position = videoRef.current.position.clone();
        const targetPosition = pos.clone();

        position.x = lerp(position.x, targetPosition.x, 0.2);
        position.y = lerp(position.y, targetPosition.y, 0.2);

        const velocityX = (targetPosition.x - position.x);
        const velocityY = (targetPosition.y - position.y);

        videoRef.current.material.uniforms.uOffset.value.x = velocityX * 2.0;
        videoRef.current.material.uniforms.uOffset.value.y = velocityY * 2.0;

        videoRef.current.position.x = position.x;
        videoRef.current.position.y = position.y;
    });

    // Animate the video plane when the mouse hovers over it
    useEffect(() => {
        if (isVisible) {
            gsap.to(videoRef.current.material.uniforms.uAlpha, {
                value: 0.9,
                duration: 0.275,
                ease: "power2.inOut"
            });
            gsap.to(videoRef.current.scale, {
                x: 1,
                y: 1,
                duration: 0.175,
                ease: "power1.inOut"
            });
        } else {
            gsap.to(videoRef.current.material.uniforms.uAlpha, {
                value: 0.0,
                duration: 0.275,
                ease: "power2.inOut"
            });
            gsap.to(videoRef.current.scale, {
                x: 0.9,
                y: 0.9,
                duration: 0.175,
                ease: "power1.inOut"
            });
        }
    }, [isVisible]);

    return (
        <mesh ref={videoRef}>
            <DisplacementMaterial attach="material" uniforms={uniforms} />
            <planeGeometry attach="geometry" args={[planeWidth, planeHeight]} />
        </mesh>
    );
}

export default VideoFrame;