import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import lottie from "lottie-web";
import animationData from '../assets/lotties/Neublau_Logo_Animation.json';

function Header() {
    const lottieRef = useRef(null);

    useEffect(() => {
        var animDuration = 2000;
        const anim = lottie.loadAnimation({
            container: lottieRef.current,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: animationData,
            initialSegment: [0, 38],
        });

        function animateOnScroll(duration) {
            const scrollPosition = window.scrollY;
            const maxFrames = 70;
            const initalFrames = anim.totalFrames; // frames of the logo animation
            const scrollFrames = maxFrames - initalFrames; // frames of the scroll animation
            const scrollSegment = initalFrames + (maxFrames / 100) * (scrollPosition / (duration / 100)) % scrollFrames; // calculate the current frame of the scroll animation and loops it with modulo operator
            anim.goToAndStop(scrollSegment, true);
        }

        const onScroll = () => {
            if (window.scrollY > 0) {
                anim.setDirection(1);
                anim.play();
            } else if ((window.scrollY < 1)) {
                anim.setDirection(-1);
                anim.play();
            }

            if (window.scrollY > 0 && anim.currentFrame >= (anim.totalFrames - 1)) {
                animateOnScroll(animDuration);
            }
        };

        document.addEventListener("scroll", onScroll);

        return () => {
            anim.destroy();
            document.removeEventListener("scroll", onScroll);
        };
    }, []);

    return (
        <header className="site-header">
            <div className="site-header__logo">
                <Link to="/">
                    <div
                        ref={lottieRef}
                        style={{ width: '200px' }}
                    />
                </Link>
            </div>
        </header>
    )
}

export default Header