import { Link } from 'react-router-dom';
import { useRef, useEffect, useState } from 'react';

function Footer({ path }) {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const footerRef = useRef();

    useEffect(() => {
        const footer = footerRef.current;
        const footerHeight = footer.offsetHeight;
        if (path === '/') {
            footer.style.display = 'flex';
            footer.style.background = 'linear-gradient(0deg, rgba(65, 0, 211, 1) 0%, rgba(65, 0, 211, 0) 100%)';
            footer.style.marginTop = `-${footerHeight}px`;
        }
        else if (path.includes('/services/')) {
            footer.style.display = 'none';
        } else {
            footer.style.background = 'var(--secondary)';
            footer.style.marginTop = '0';
        }
    }, [windowSize, path])

    return (
        <footer ref={footerRef} className="site-footer">
            <div className="site-footer__copyright">
                © 2023
            </div>
            <div className="site-footer__navigation">
                <Link to="/datenschutz">
                    Datenschutz
                </Link>
                <Link to="/impressum">
                    Impressum
                </Link>
            </div>
        </footer>
    )
}

export default Footer