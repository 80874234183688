import { useParams } from 'react-router-dom';
import { fetchData } from '../hooks/useFetch.js';
import { useState, useContext, useEffect, useRef, useLayoutEffect } from 'react';

import { RichText } from '@graphcms/rich-text-react-renderer';
import gsap from 'gsap'

import Media from '../components/Media.js';
import Gallery from '../components/Gallery.js';
import NextService from '../components/NextService.js';

import { ScreenContext } from '../context/ScreenContext.js';
import { useLocation } from 'react-router-dom'

import { motion } from 'framer-motion'

function Service() {
    const { setContextData } = useContext(ScreenContext);
    const location = useLocation();

    const variants = {
        initial: () => {
            if (location.state?.prevPath === "/") {
                return {}
            } else {
                return { clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)' }
            }
        },
        animate: {
            clipPath: 'polygon(0 100%, 100% 100%, 100% 100%, 0 100%)',
            transition: { duration: 1, ease: [0.87, 0, 0.13, 1] }
        },
        exit: {
            clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
            transition: { duration: 0.675, ease: [0.87, 0, 0.13, 1] }
        }
    }

    useEffect(() => {
        setContextData((prevValue) => ({ ...prevValue, clicked: false, slug: null, hoveredItem: null }))
    }, [setContextData])

    const [serviceData, setServiceData] = useState();
    const [allServicesData, setAllServicesData] = useState();

    const { slug } = useParams();

    const [nextService, setNextService] = useState({
        title: '',
        slug: '',
        color: '',
        heroMedia: {
            url: '',
            mimeType: '',
        }
    });

    useLayoutEffect(() => {
        const dataFetch = async () => {
            const [serviceDataResult, allServicesResult] = await Promise.all([
                fetchData(`{
                    services(where: {slug: "${slug}"}) {
                        createdAt
                        id
                        publishedAt
                        title
                        updatedAt
                        slug
                        description {
                          raw
                        }
                        heroMedia {
                          url
                          width
                          height
                          mimeType
                        }
                        topics(first: 50) {
                          ... on Topic {
                            id
                            Topic_title_alias: title
                            Topic_description_alias: description
                            Topic_media_alias: media {
                              id
                              stage
                              updatedAt
                              handle
                              fileName
                              mimeType
                              width
                              height
                              size
                              url
                            }
                          }
                        }
                      }
                  }`),
                fetchData(`{
                    services {
                        title
                        slug
                        heroMedia {
                            url
                            width
                            height
                            mimeType
                          }
                      }
                  }`)
            ])

            setServiceData(serviceDataResult);
            setAllServicesData(allServicesResult);
        }

        dataFetch();

    }, [slug]);

    useLayoutEffect(() => {
        if (allServicesData) {
            allServicesData.services.forEach((service, index) => {
                if (service.slug === slug) {
                    let nextIndex = index + 1;
                    if (allServicesData.services[nextIndex]) {
                        setNextService({ title: allServicesData.services[nextIndex].title, slug: allServicesData.services[nextIndex].slug, heroMedia: { url: allServicesData.services[nextIndex].heroMedia.url, mimeType: allServicesData.services[nextIndex].heroMedia.mimeType } });
                    } else {
                        setNextService({ title: allServicesData.services[0].title, slug: allServicesData.services[0].slug, heroMedia: { url: allServicesData.services[0].heroMedia.url, mimeType: allServicesData.services[0].heroMedia.mimeType } });
                    }
                }
            })
        }

    }, [allServicesData, slug]);

    const mainRef = useRef();

    useLayoutEffect(() => {

        if (serviceData) {
            let ctx = gsap.context((self) => {
                gsap.to(self.selector(".hero__media"), {
                    scrollTrigger: {
                        trigger: self.selector(".hero"),
                        start: "top top",
                        end: "bottom top",
                        scrub: true,
                        pinSpacing: false
                    },
                    clipPath: 'inset(0% 0% 100% 0%)',
                    ease: "linear",
                });
            }, mainRef);

            return () => ctx.revert(); // cleanup
        }

    }, [serviceData]);

    const introRef = useRef();


    return (
        serviceData &&
        <main
            ref={mainRef}
            id="main"
            className="service-page"
            key={serviceData.services[0].id}
        >
            <section
                className="hero">
                <Media className="hero__media"
                    src={serviceData.services[0].heroMedia.url}
                    mimeType={serviceData.services[0].heroMedia.mimeType}
                    alt="" />
                <div className="hero__content">
                    <h1 className="hero__headline ff-cy-light fs-700 line-height-2 uppercase">
                        {serviceData.services[0].title}
                    </h1>
                </div>
            </section>
            <section className="intro" ref={introRef}>
                <div className="intro__content">
                    <RichText content={serviceData.services[0].description.raw} />
                </div>
            </section>
            <Gallery topics={serviceData.services[0].topics} innerRef={introRef} />

            <NextService nextService={nextService} />
            <motion.div
                variants={variants}
                initial="initial"
                animate="animate"
                exit="exit"
                className="page-transition-overlay"
            >
            </motion.div>
        </main>
    )
}

export default Service