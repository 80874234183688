import { useEffect, useMemo, useState } from 'react';

import {
    Routes, Route, useLocation
} from "react-router-dom";

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Lenis from '@studio-freight/lenis'

import Header from './Header';
import CookieBanner from './CookieBanner';
import Footer from './Footer';
import Home from '../pages/Home';
import Service from '../pages/Service';
import Impressum from '../pages/Impressum';
import Datenschutz from '../pages/Datenschutz';

import Screen from '../components/Screen';

import { AnimatePresence } from 'framer-motion';
import Preloader from './Preloader';

function Layout({ windowWidth }) {

    const [isLoaded, setIsLoaded] = useState(false);

    const location = useLocation();

    const lenis = useMemo(() => new Lenis(), []);

    // Scroll to top on route change
    useEffect(() => {

        lenis.on('scroll', ScrollTrigger.update)

        gsap.ticker.add((time) => {
            lenis.raf(time * 1000)
        })

        function raf(time) {
            lenis.raf(time)
            requestAnimationFrame(raf)
        }

        requestAnimationFrame(raf)

    }, [lenis, location.pathname]);

    return (
        <>
            <Preloader isLoaded={isLoaded} setIsLoaded={setIsLoaded} />
            <CookieBanner />
            <Header />
            <AnimatePresence initial={false} mode="wait">
                <Routes location={location} key={location.pathname}>
                    <Route path="/" element={<Home windowWidth={windowWidth} />} />
                    <Route path="/services/:slug" element={<Service />} />
                    <Route path="/impressum" element={<Impressum />} />
                    <Route path="/datenschutz" element={<Datenschutz />} />
                </Routes>
            </AnimatePresence>
            <Screen />
            <Footer path={location.pathname} />
        </>
    )
}

export default Layout