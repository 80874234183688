import ReactGA from 'react-ga4';
import { useContext, useEffect } from 'react';
import { useFetch } from '../hooks/useFetch.js';

import Error from '../components/Error';
import Loading from '../components/Loading';

import ShuffleSpan from '../components/ShuffleSpan';
import Services from '../components/Services';
// import Screen from '../components/Screen';

import heroVideo from '../assets/videos/heroVideo.mp4';
import heroVideoWebm from '../assets/videos/heroVideo.webm';
import aboutVideo from '../assets/videos/aboutVideo.mp4';
import aboutVideoWebm from '../assets/videos/aboutVideo.webm';

import getInTouchSvg1 from '../assets/patterns/get-in-touch1.svg';
import getInTouchSvg2 from '../assets/patterns/get-in-touch2.svg';

import { ScreenContext } from '../context/ScreenContext.js';

function Home({ windowWidth }) {

    // Google Analytics
    const sendGAEvent = () => {
        ReactGA.event({
            category: "Contact",
            action: "mailto_link_clicked",
        });
    };

    // Fetch Data for Home
    const { data, loading, error } = useFetch(`{
        services {
            createdAt
            id
            publishedAt
            title
            slug
            updatedAt
            heroMedia {
            url
            width
            height
            mimeType
            }
        }
    }`);

    // Add a list of services to the ScreenContext
    const { setContextData } = useContext(ScreenContext);

    useEffect(() => {
        setContextData((prevValue) => ({ ...prevValue, services: data?.services }))
    }, [setContextData, data])

    return (
        <>
            <main id="main" className="home">
                <section className="hero">
                    <video className="hero__video" playsInline autoPlay loop muted>
                        <source src={heroVideoWebm} type="video/webm" />
                        <source src={heroVideo} type="video/mp4" />
                    </video>
                    <div className="hero__content">
                        <h1 className="hero__headline ff-cy-bold fs-700 line-height-3">
                            Wir&nbsp;
                            <ShuffleSpan onInterval className="words" phrases={['gestalten', 'programmieren', 'managen', 'konzipieren']}>konzipieren</ShuffleSpan>
                            <br />Ihre digitale <br />
                            Identität
                        </h1>
                    </div>
                </section>
                <section className="intro">
                    <div className="intro__content m-2">
                        <p>
                            Wir konzipieren, designen und programmieren Websites, die die Stärken von Unternehmen und Marken emotional kommunizieren und intuitiv erfahrbar machen. Von kompakten One-Pagern und Landingpages bis hin zu hoch komplexen Plattformlösungen: In jedem Projekt arbeiten wir die für die Kommunikation passende UX heraus.
                        </p>
                    </div>
                </section>

                {loading && <Loading />}
                {error && <Error />}
                {data && <Services services={data.services} windowWidth={windowWidth} />}

                <section className="about">
                    <video className="about__video" playsInline autoPlay loop muted>
                        <source src={aboutVideo} type="video/mp4" />
                        <source src={aboutVideoWebm} type="video/webm" />
                    </video>
                    <div className="about__content">
                        <p className="about__headline ff-cy-bold fs-500 line-height-2 m-1">
                            <ShuffleSpan runOnScroll>Think.</ShuffleSpan><br />
                            <ShuffleSpan runOnScroll>Talk.</ShuffleSpan><br />
                            <ShuffleSpan runOnScroll>Create ... Enjoy!</ShuffleSpan>
                        </p>
                        <p className="about__text">
                            Wir lieben kurze Wege, intern und extern, und reden gerne direkt mit der verantwortlichen Person auf Kundenseite. Das gleiche Prinzip gilt auch in anderer Richtung. Kurze Wege sparen Zeit, Geld und schaffen damit mehr Raum für ein besseres Ergebnis.
                        </p>
                    </div>
                </section>
                <section className="contact">
                    <div className="contact__hero">
                        <h2 className="visually-hidden">Kontakt</h2>
                        <img src={getInTouchSvg1} alt="" />
                        <img src={getInTouchSvg2} alt="" />
                    </div>
                    <div className="contact__content">
                        <p>
                            Sie haben ein Projekt<br />
                            oder ein "digitales" Problem?<br />
                            Wir haben Lösungen.
                        </p>
                        <div className="mail">
                            <a className="bounce ff-helv-bold fs-600" href="mailto:hello@neublau.digital?subject=Anfrage%20zur%20Zusammenarbeit&body=Guten%20Tag%2Cich%20habe%20ein%20Projekt%20in%20Planung%20und%20bin%20an%20Ihrer%20Expertise%20interessiert.%20K%C3%B6nnten%20Sie%20mir%20bitte%20mehr%20Informationen%20und%20ein%20Angebot%20zu%20%5BDienstleistung%2FProjekt%5D%20zukommen%20lassen%3F%5BProjektinformationen%5DVielen%20Dank%20und%20freundliche%20Gr%C3%BC%C3%9Fe%2C%5BVorname%5D%20%5BNachname%5D" onClick={sendGAEvent}><ShuffleSpan runOnScroll>hello@neublau.digital</ShuffleSpan></a>
                        </div>
                    </div>
                </section>
            </main>

            {/* {loading && <Loading />}
            {error && <Error />}
            {data && <Screen services={data.services} />} */}

        </>

    )
}

export default Home