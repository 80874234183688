import React, { useEffect, useContext } from 'react';
import { Canvas } from '@react-three/fiber'
import Scene from './Scene';
import Media from './Media';

// import { useLocation } from 'react-router-dom'

import { ScreenContext } from '../context/ScreenContext';

function Services({ services, windowWidth }) {

    const { contextData, setContextData } = useContext(ScreenContext);

    // const location = useLocation();

    useEffect(() => {
        const handleMouseMove = (event) => {
            const { clientX, clientY } = event;
            if (!Number.isFinite(clientX) || !Number.isFinite(clientY)) {
                return;
            }
            // Set the mouse position in css variables
            document.documentElement.style.setProperty('--x', `${clientX}px`);
            document.documentElement.style.setProperty('--y', `${clientY}px`);

            setContextData((prevValue) => ({ ...prevValue, mousePos: { x: clientX, y: clientY } }));
        };

        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, [setContextData]);

    function debounce(fn, delay) {
        let timerId;
        return function (...args) {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                fn(...args);
            }, delay);
        };
    }

    useEffect(() => {
        const handleScroll = debounce(() => {
            const { x, y } = contextData.mousePos;
            if (!Number.isFinite(x) || !Number.isFinite(y)) {
                return;
            }
            const element = document.elementFromPoint(x, y);
            if (element && element.classList.contains("title")) {
                const index = parseInt(element.getAttribute("data-index"));
                setContextData((prevValue) => ({ ...prevValue, hoveredItem: prevValue.hoveredItem !== index ? index : prevValue.hoveredItem }));
            } else {
                setContextData((prevValue) => ({ ...prevValue, hoveredItem: null }));
            }
        }, 15); // Change the delay time as per your requirement


        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [contextData.mousePos, setContextData]);

    function handleClick(slug, index) {
        setContextData((prevValue) => ({ ...prevValue, mouseInputs: false, clickedItem: index, clicked: true, slug: prevValue.slug !== slug ? slug : prevValue.slug }))
    }

    function mouseEnter(index) {
        setContextData((prevValue) => ({ ...prevValue, hoveredItem: prevValue.hoveredItem !== index ? index : prevValue.hoveredItem }))
    }

    function mouseLeave() {
        setContextData((prevValue) => ({ ...prevValue, hoveredItem: null }))
    }

    const serviceItems = services.map((service, index) => {
        return (<article key={index} className="services__item">
            <h3 data-index={index} className="title ff-cy-light fs-700 line-height-2 uppercase" onMouseEnter={() => mouseEnter(index)} onMouseLeave={() => mouseLeave()} onClick={() => handleClick(service.slug, index)}>
                {service.title}</h3>
            {windowWidth <= 769 && <Media src={service.heroMedia.url} mimeType={service.heroMedia.mimeType} />}
        </article>)
    })

    return (
        <section className="services">
            <div className="services__content" style={{ zIndex: '1', position: 'relative' }}>
                <h2 className="visually-hidden">Leistungen</h2>
                {serviceItems}
            </div>
            {windowWidth >= 769 &&
                <Canvas camera={{ fov: 35, near: 0.1, far: 50, position: [0, 0, 1] }} style={{ position: 'fixed', top: 0, left: 0, inset: '0', zIndex: '0', pointerEvents: 'none' }}>
                    <Scene services={services} hoveredItem={contextData.hoveredItem} />
                </Canvas>
            }
        </section>
    )
}

export default Services