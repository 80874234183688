import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useFrame } from '@react-three/fiber'
import * as THREE from "three";
import { useTexture } from '@react-three/drei';
import gsap from 'gsap';
import { lerp } from '../utilities/utils.js';
import DisplacementMaterial from './DisplacementMaterial.js';

function Frame({ service, pos, hoveredItem, index, viewport }) {

    const [isVisible, setIsVisible] = useState(false);
    const [planeWidth, setPlaneWidth] = useState(0);
    const [planeHeight, setPlaneHeight] = useState(0);

    // Change the isVisible state when the hoveredItem changes
    useEffect(() => {
        setIsVisible(hoveredItem === index);
    }, [hoveredItem, index]);

    const image = service.heroMedia.url;

    const imageRef = useRef();
    const imageTexture = useTexture(image);

    const uniforms = useMemo(
        () => ({
            uOffset: { value: new THREE.Vector2(0, 0) },
            uAlpha: { value: 0.0 },
            uTexture: { value: imageTexture }
        }), [imageTexture]
    );

    // Set the plane dimensions based on the image dimensions
    useMemo(() => {
        const aspectRatio = service.heroMedia.width / service.heroMedia.height;

        const maxViewportWidth = viewport.width / 2;
        const maxViewportHeight = viewport.height / 1.5;

        if (aspectRatio < 1) {
            setPlaneWidth(maxViewportHeight * aspectRatio);
            setPlaneHeight(maxViewportHeight);
        }
        else if (aspectRatio > 1) {
            setPlaneWidth(maxViewportWidth);
            setPlaneHeight(maxViewportWidth / aspectRatio);
        }
        else {
            setPlaneWidth(maxViewportWidth);
            setPlaneHeight(maxViewportWidth);
        }
    }, [service.heroMedia.width, service.heroMedia.height, viewport.width, viewport.height]);

    // Set the plane position based on the mouse position
    useFrame(() => {
        const position = imageRef.current.position.clone();
        const targetPosition = pos.clone();

        position.x = lerp(position.x, targetPosition.x, 0.2);
        position.y = lerp(position.y, targetPosition.y, 0.2);

        const velocityX = (targetPosition.x - position.x);
        const velocityY = (targetPosition.y - position.y);

        imageRef.current.material.uniforms.uOffset.value.x = velocityX * 2.0;
        imageRef.current.material.uniforms.uOffset.value.y = velocityY * 2.0;

        imageRef.current.position.x = position.x;
        imageRef.current.position.y = position.y;
    });

    // Animate the image plane when the mouse hovers over it
    useEffect(() => {
        if (isVisible) {
            gsap.to(imageRef.current.material.uniforms.uAlpha, {
                value: 0.9,
                duration: 0.275,
                ease: "power2.inOut"
            });
            gsap.to(imageRef.current.scale, {
                x: 1,
                y: 1,
                duration: 0.175,
                ease: "power1.inOut"
            });
        } else {
            gsap.to(imageRef.current.material.uniforms.uAlpha, {
                value: 0.0,
                duration: 0.275,
                ease: "power2.inOut"
            });
            gsap.to(imageRef.current.scale, {
                x: 0.9,
                y: 0.9,
                duration: 0.175,
                ease: "power1.inOut"
            });
        }
    }, [isVisible]);

    return (
        <mesh ref={imageRef}>
            <DisplacementMaterial attach="material" uniforms={uniforms} />
            <planeGeometry attach="geometry" args={[planeWidth, planeHeight]} />
        </mesh>
    );
}

export default Frame;