import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger)

function ShuffleSpan({ children, className, onInterval = false, runOnScroll = false, runOnHover, phrases }) {
    const [counter, setCounter] = useState(0);
    const elRef = useRef(null);
    const phrasesRef = useRef(phrases);

    useEffect(() => {
        const el = elRef.current;
        let frameRequest;
        let resolve;
        let queue = [];
        let frame = 0;

        const chars = "!<>-_\\/[]{}—=+*^?#________%.";

        function setText(newText) {
            const oldText = el.innerText;
            const length = Math.max(oldText.length, newText.length);
            const promise = new Promise((res) => (resolve = res));
            queue = [];

            for (let i = 0; i < length; i++) {
                const from = oldText[i] || "";
                const to = newText[i] || "";
                const start = Math.floor(Math.random() * 10);
                const end = start + Math.floor(Math.random() * 10);

                queue.push({ from, to, start, end });
            }

            cancelAnimationFrame(frameRequest);
            frame = 0;
            update();
            return promise;
        }

        function update() {
            let output = "";
            let complete = 0;

            for (let i = 0, n = queue.length; i < n; i++) {
                let { from, to, start, end, char } = queue[i];

                if (frame >= end) {
                    complete++;
                    output += to;
                } else if (frame >= start) {
                    if (!char || Math.random() < 0.28) {
                        char = randomChar();
                        queue[i].char = char;
                    }
                    output += `<span>${char}</span>`;
                } else {
                    output += from;
                }
            }

            el.innerHTML = output;

            if (complete === queue.length) {
                resolve();
            } else {
                frameRequest = requestAnimationFrame(update);
                frame++;
            }
        }

        function randomChar() {
            return chars[Math.floor(Math.random() * chars.length)];
        }

        function animateText(newText) {
            setText(newText).then(() => {
                frameRequest = requestAnimationFrame(() => {
                    el.innerHTML = newText;
                });
            });
        }

        if (onInterval) {
            const interval = setInterval(() => {
                animateText(phrasesRef.current[counter]);
                setCounter((counter + 1) % phrasesRef.current.length);
            }, 1750)

            return () => {
                clearInterval(interval);
            }
        }

        if (runOnScroll) {
            let ctx = gsap.context(() => {
                ScrollTrigger.create({
                    trigger: el,
                    start: 'bottom bottom',
                    onEnter: () => {
                        animateText(children);
                    },
                });
            }, elRef);
            return () => {
                ctx.revert();
            }
        }

        return () => {
            cancelAnimationFrame(frameRequest)
        }
    }, [children, onInterval, runOnScroll, counter]);

    return <span className={`shuffle ${className}`} ref={elRef}>{children}</span>;
}

export default ShuffleSpan;