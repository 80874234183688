
import ReactDOM from 'react-dom';
import { useContext, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Media from './Media';

import gsap from 'gsap';

import { ScreenContext } from '../context/ScreenContext.js';

function Screen() {

    const serviceRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    const { contextData } = useContext(ScreenContext);
    const { slug, clicked, clickedItem, services, index } = contextData;

    useEffect(() => {

        if (clicked) {
            const serviceTl = gsap.timeline();

            if (!serviceTl.isActive()) {
                serviceRef.current.style.display = 'block';

                serviceTl
                    .to(serviceRef.current, {
                        duration: 0,
                        opacity: 1,
                    })
                    .to(serviceRef.current, {
                        duration: 1,
                        clipPath: () => `circle(150% at var(--x, 50%) var(--y, 50%))`,
                        ease: 'power3.inOut',
                        onComplete: () => {
                            navigate("/services/" + slug, { state: { prevPath: location.pathname } });
                            serviceTl.to(serviceRef.current, {
                                duration: 0.5,
                                opacity: 0,
                                clipPath: `circle(0% at var(--x, 50%) var(--y, 50%))`,
                                ease: 'power3.inOut',
                            }, "+=0.5")
                        }
                    })
            }
        }

    }, [clicked, slug, navigate, location.pathname])

    return ReactDOM.createPortal(
        <div ref={serviceRef} className="screen__content" data-index={index} style={{ opacity: 0 }}>
            {services && <Media src={services[clickedItem]?.heroMedia.url} mimeType={services[clickedItem]?.heroMedia.mimeType} />}
        </div>,
        document.getElementById('screen')
    );
}

export default Screen