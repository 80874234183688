
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Media from './Media';
// import gsap from 'gsap';
// import Service from '../pages/Service';

// import { motion } from 'framer-motion'

function NextService({ nextService }) {

    const navigate = useNavigate();

    const nextServiceRef = useRef(null);
    const nextServiceOverlayRef = useRef(null);

    // useLayoutEffect(() => {
    //     const ctx = gsap.context((self) => {
    //         gsap.to(nextServiceOverlayRef.current, {
    //             scrollTrigger: {
    //                 trigger: nextServiceRef.current,
    //                 start: "top top",
    //                 end: "+=25%",
    //                 pin: true,
    //                 scrub: true,
    //             },
    //             backdropFilter: 'blur(0px)',
    //         })
    //     }, nextServiceRef);

    //     return () => {
    //         ctx.revert();
    //     } // cleanup

    // }, [navigate]);

    return (
        <section ref={nextServiceRef} className="next-service-hero" onClick={() => navigate('/services/' + nextService.slug)}>
            <div className="next-service-hero__media">
                <Media src={nextService.heroMedia.url} mimeType={nextService.heroMedia.mimeType} alt="" />
                <div ref={nextServiceOverlayRef} className="next-service-hero__media-overlay"></div>
            </div>
            <div className="next-service-hero__content">
                <div className="next-service-hero__headline ff-cy-light fs-700 line-height-2 uppercase">{nextService.title}</div>
            </div>
        </section>
    )
}

export default NextService