import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lottie from 'lottie-react';
import animationData from '../assets/lotties/Wave_Animation.json';
import { useEffect, useRef } from 'react';

gsap.registerPlugin(ScrollTrigger);

function Preloader({ isLoaded, setIsLoaded }) {

    const lottieRef = useRef(null);

    const lottieConfig = {
        animationData,
        loop: false,
        autoplay: false,
    };

    useEffect(() => {
        if (lottieRef.current !== null) {
            setIsLoaded(true);
        }
        if (isLoaded) {
            setTimeout(() => {
                lottieRef.current.play();
            }, 100);
        }
    }, [lottieRef, isLoaded, setIsLoaded]);


    return (
        <div style={{ position: 'fixed', width: '100vw', height: '100vh', overflow: 'hidden', zIndex: 9999, pointerEvents: 'none' }}>
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                <Lottie
                    lottieRef={lottieRef}
                    {...lottieConfig}
                    rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                    style={{ height: '100%' }}
                />
            </div>
        </div>
    )
}

export default Preloader